// 首页
<template>
    <div class="container">
        <div class="pho">
            <van-swipe class="my-swipe" :autoplay="10000" indicator-color='#f56a00' ref='swiper'>
               <van-swipe-item><a href="/heating-cable?active=one" target="_blank"><img :src="require('@/assets/css/banner/ditu.png')" alt="" class="ditu"><img :src="require('@/assets/css/banner/tubiao.png')" alt="" class="tubiao"><div class="div"><div class="box">低碳清洁供暖<br><i class="i">LOW CARBON AND RENEWABLE HEATING</i></div><div class="p">分布式 多热源 冷暖一体化服务</div></div></a></van-swipe-item> 
                <van-swipe-item><a href="/hot-water"  target="_blank"><img :src="require('@/assets/css/banner/di.png')" alt=""  class="ditu"><div class="blue"><div class="box">生活热水服务<br><i>WATER HEATING SUPPLY</i></div><div class="p">生活热水 淋浴 直饮水 衍生消费系统</div></div></a></van-swipe-item>
                <van-swipe-item><a href="/photovoltaic-array?active=one" target="_blank"><img :src="require('@/assets/css/banner/fengche.png')" alt=""  class="ditu"><div class="div"><div class="box">综合能源管理<br><i>INTEGRATED ENERGY MANAGEMENT</i></div><div class="p">光伏 微电网 能源站 余热回收</div></div></a></van-swipe-item>
                <van-swipe-item><a href="/system?active=one" target="_blank"><img :src="require('@/assets/css/banner/zhikong.png')" alt=""  class="ditu"><div class="origin"><div class="box">瑞海智慧供热管理系统<br><i class="i">INTELLIGENT HEATING MANAGEMENT SYSTEM</i></div><div class="p">物联网 云计算 大数据</div></div></a></van-swipe-item>
            </van-swipe>
            <div class="vanBtn">
                <button @click='prev()' class="left"><img :src="require('@/assets/css/banner/left.png')" alt=""></button>
                <button @click='next()' class="right"><img :src="require('@/assets/css/banner/right.png')" alt=""></button>
            </div>

                 <!-- <video :src='videoUrl' :poster="videoUrl + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,ar_auto'" width="100%"  muted autoplay="autoplay" loop="loop" x5-video-player-type="h5" playsinline webkit-playsinline v-if='this.deviceType === "pc"'></video>
                 <img :src= "require('@/assets/clear.jpg')"  v-else-if="this.deviceType === 'mobile'"> -->
        </div>
        <div class="home">
            <div class="key">
                <h4>热门搜索关键词 :</h4>
                <h4><a href="heating-cable?active=one" target="_blank">跨热源供暖</a></h4>
                <h4><a href="system?active=one" target="_blank">智慧供热平台</a></h4>
                <h4><a href="school?active=one" target="_blank">校园综合供暖</a></h4>
                <h4><a href="hot-water" target="_blank">生活热水</a></h4>
            </div>
            <div class="mingle">
                <Mingle></Mingle>
            </div>
            <div class="mp">
                <div class="vi">
                    <div class="vishow">
                        <video width="550" :src='videoUrl1' :poster="vimg1" controls ref="videoPlay" ></video>
                    </div>
                    <div class="vishow">
                         <video width="550" :src='videoUrl2' :poster=" vimg2 " controls ref="videoPlays"></video>
                    </div>
                </div>
            </div>
            <Secondary :title='title1'></Secondary>
            <Print :text='text1'></Print>
            <Pho :IMG='img1'></Pho>
            <Print :text='text2'></Print>
            <Pho :IMG='img2'></Pho>
            <p>智慧能源云系统可进行综合数据处理，发挥信息联动。分析建筑能耗动静态因素，实现数字化管理。</p>
            <div class="bg">
                <Secondary :title='title2'></Secondary>
                <van-row type="flex" justify="space-between">
                   <van-col span="11">
                       <img :src='require("@/assets/css/首页/3.png")'>
                   </van-col>
                   <van-col span="12">
                       <ul>
                           <li @click="toguid('/great-case','school')">太原学院新校区综合供暖项目</li>
                           <li @click="toguid('/great-case','xinzhou')">山西忻州同煤大厦智能化供暖项目</li>
                           <li @click="toguid('/great-case','power')">国家电网太原分公司高层宿舍集中供暖项目</li>
                           <li @click="toguid('/great-case','nengyuan')">太原国际能源中心</li>
                           <li @click="toguid('/great-case','changzhi')">长治交通职业技术学院</li>
                           <li @click="toguid('/great-case','datong')">大同市世家康养小镇</li>
                       </ul>
                   </van-col>
                </van-row>
            </div>
            <el-carousel :interval="3000" type="card" height="360Px"  arrow="never" v-lazy-container="{ selector: 'img' }">
                <el-carousel-item v-for="item in IMG" :key="item.id" >
                <a :href="item.href" target="_blank"><img :data-src="item.img" alt="">
                <p class="itemtitle">{{item.title}}</p>
                </a>
                </el-carousel-item>
            </el-carousel>
        </div>
        <p class="more"><a href="/news-information">更多新闻...</a></p>
        
    </div>
</template>
<script>
import Mingle from '@/common/mingle'
import Secondary from '@/common/secondary'
import Print from '@/common/print.vue'
import Pho from '@/common/pho.vue'
export default {
    methods:{	
        
	toguid(path,id){
		var Id=id;
		localStorage.setItem('toId',Id);
		this.$router.push(path);
	},
    prev(){
        this.$refs.swiper.prev()
    },
    next(){
        this.$refs.swiper.next()
    },
    toS(){
        // this.$router.replace('/school')
        // this.$router.push({path:'/school'})
        //  window.open('http://localhost:8081/heating-cable?active=one')
    }
//     _isMobile() {
//             let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
//             return flag;
//         },
// },
// mounted() {
//         if (this._isMobile()) {
//             this.deviceType = 'mobile'
//         } else {
//             this.deviceType = 'pc'
//         }
    },
    components: {
        Mingle,
        Secondary,
        Print,
        Pho,
    },
    data() {
        return {
            title1:'瑞海智慧能源云平台',
            deviceType: '',
            vimg1:require('@/assets/er.png'),
            vimg2:require('@/assets/yi.png'),
            // videoUrl:require('@/assets/clear.mp4'),
            videoUrl1:require('@/assets/er.mp4'),
            videoUrl2:require('@/assets/yi.mp4'),
            text1:'随着互联网时代的到来，信息传输模式发生了翻天覆地的改变，各行各界都在进行数据的改革，人们对于舒适，也有了全新的定义。在供热领域，由于建筑电气化程度不断加强，建筑能耗也随之增加，造成了运行方的能源供给和用户日常使用费用的压力同步增长。由于建筑能耗是一个复杂且受多方因素影响的难题，现阶段根据单一热负荷系数的线性数据分析而建立所谓的“智慧”系统，最后也只能沦为展示厅中一块尴尬的数据显示屏，而跟智能“搭”不上半点关系。',
            img1:require('@/assets/css/首页/智能控制2.jpg'),
            text2:'供热系统设施设备逐步从单一功能向综合能源系统协同发展。综合能源智能服务成为供热行业新的技术投、研究的重点。然而，市场上却缺乏一个能真正融合多种数据来源的动态管控能源管理系统。瑞海热控科研团队针对现状，潜心数载，研发出基于物联网、云计算和智能控制技术的综合能源控制平台——瑞海智慧供热管理系统。',
            img2:require('@/assets/css/首页/数据分析图.png'),
            title2:'精彩案例',
            IMG:[
				{id:1,img:require('@/assets/zth.jpg'),title:'瑞海热控|品促会暨2025年智慧供热新春座谈会顺利召开',href:'https://mp.weixin.qq.com/s/JC8IHJGGEO_JKlkt3MGOCQ'},
                {id:2,img:require('@/assets/lathree.jpg'),title:'瑞海热控总经理秦洋受邀山西青年报专访——创新引领智慧供热 打造能源新路径',href:'https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=2247488312&idx=1&sn=6b2aa78f6ae567efd23a268769a5d8a5&scene=19#wechat_redirect'},
                {id:3,img:require('@/assets/laone.png'),title:'瑞海新闻|热烈祝贺瑞海热控获得“高新技术企业”认证',href:'https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=2247488258&idx=1&sn=1aee652eb6ac6d23c2f1aec174891ada&scene=19#wechat_redirect'},
                {id:4,img:require('@/assets/latwo.png'),title:'瑞海新闻|瑞海热控荣获“专精特新”中小企业称号',href:'https://mp.weixin.qq.com/s?__biz=MzI5MDk5MDc2Mg==&mid=2247488254&idx=1&sn=ae1b2361353027f4b8d5c83779db4c88&scene=19#wechat_redirect'},
				],
        }
    },
    
}
</script>
<style>
.container .pho .van-swipe__indicator {
    width: 100Px;
    height: 6Px;
    -webkit-transition: 0.3s;
    border-radius: 0;
    margin-left: 10px;
    transition: 0.2s;
    background-color: #fff;
    opacity: 1;
}
</style>
<style lang="less" scoped>

p{
    width: 1200Px;
    margin: 0 auto;
    line-height: 120Px;
    text-align: center;
    font-size: 16Px;
}

.el-carousel{
    width: 1200Px;
    margin: 0 auto;
    height: 100%;
    a{
        display: block;
        width: 100%;
        height: 100%;
    }
   
    img{
        width: 100%;
        height: 100%;
        position: relative;
        // object-fit: contain;
        display: block;
object-fit: cover;
        
    }


    // p{
    //     padding: 0 auto !important;
    //     box-sizing: border-box;
    //     color: #cfcfcf;
    //     text-align: justify;
    //     background-color: rgba(0,0,0,.5) !important;
    //     display: inline-block;
    //     position: absolute;
    //     bottom: 20Px;
    //     line-height: 90Px;
    //     font-size: 16Px;
    // }
    
}
.itemtitle{
        width: 100%;
        display: flex !important;
        justify-content:center !important;
        box-sizing: border-box;
        color: #cfcfcf;
        text-align: justify;
        background-color: rgba(0,0,0,.5) !important;
        display: inline-block;
        position: absolute;
        bottom: 20Px;
        line-height: 90Px;
        font-size: 16Px;

}
.img video{
        display: block;
        margin: auto;
        max-width: 100%;
        pointer-events: none;
}
.mp{
    width: 100%;
    margin-top: 80px;
    padding: 3% 0;
    box-sizing: border-box;
    background-color: #eff0f2;
.vi{
    margin: 0 auto;
    width: 1200Px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .vishow{
        .video-img{
	position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9999999999999;
	cursor:pointer;
    img{
      margin: auto;
      display:block;
      width: 150px;
      margin-left: 560px;
      margin-top: 300px;
      height: 150px;
    }
}
    }
}

}
ul li {
    font-size: 18Px;
    list-style: none;
    color: #333;
    cursor: pointer;
    border-bottom: 2Px solid #b5b7b6;
    height: 45Px;
    line-height: 45Px;
}
ul  li:hover{
    color: #e36606;
    border-bottom: 2Px solid #f56a00;
}
.bg{
    width: 1200Px;
    margin: 0 auto;
    background-color: #eff0f2;
    padding: 20Px 50Px;
    box-sizing: border-box;
    margin-bottom: 60Px;
}
.more{
    width: 1200Px;
    box-sizing: border-box;
    text-align: right;
    a{
        font-size: 16Px;
        color: #f56a00;
    }
}
img{
    // width: 100%;
    display: block;
    max-width: 100%;
    padding: 0;
    margin:auto;
}
.key{
    margin: 0 auto;
    height: 100%;
    width: 1200Px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.key a{
    text-decoration: none;
    color: #707070;
    font-weight: normal;
    margin-left: 40px;
}
.pho{
    width:80%;
    position: relative;
    margin: auto;
    overflow: hidden;
    vertical-align: middle;

    .van-swipe .van-swipe-item a{
        display: block;
        width: 100%;
        // height: auto;
        height: 100%;
        .van-swipe .van-swipe-item a img{
        width: 100%;
        height: 100%;
        position: absolute;
        // float: left;
        vertical-align: middle;
        z-index: 1;
        // position: absolute;
    }
   .ditu{
       width: 100%;
       height: 100%;
    
   }
     .tubiao{
         position: absolute;
       width: 100%;
       height: 100%;
        // top: -15Px;
        left: 0;
        bottom: 0;
        right: 0;
        margin-top: -10000px;
        z-index: 22;
    }
    .div{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-style: normal;
            font-size: 24Px;
        }
    }
   .p{
       margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 9999;
   }
    }
 .blue{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // right: 0;
        left: 0;
        bottom: 0;
         .box{
        background-color: #f56a00;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-style: normal;
            font-size: 24Px;
        }
        .i{
            font-size: 20Px;
        }
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 9999;
   }
    }
    .origin{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0;
        // right: 0;
        left: 0;
        bottom: 0;
         .box{
        background-color: #f56a00e4;
        padding: 30Px;
        width: 500Px;
        color: #fff;
        font-size: 30Px;
        line-height: 60Px;
        z-index: 999;
        i{
            font-style: normal;
            font-size: 24Px;
        }
        .i{
            font-size: 20Px;
        }
    }
   .p{
    margin-top: 20Px;
       background-color: #f56a00e4;
       color: #fff;
       font-size: 26Px;
       width: 500Px;
       padding: 20Px 30Px;
       z-index: 9999;
   }
    }
    }
}
  .vanBtn{
      .right{
          position: absolute;
          right: -5Px;
          top: 50%;
          background-color: rgba(0,0,0,.0);
          border: 0;
          img{
              width: 40Px;
              padding: 20Px 10Px 20PX 20Px;
              background-color: rgba(0,0,0,.4);
              border-radius: 20Px 0 0 20Px;
          }
      }
      .left{
          position: absolute;
          left: -5Px;
          top: 50%;
          background-color: rgba(0,0,0,.0);
          border: 0;
          img{
              display:block;
              width: 40Px;
              background-color: rgba(0,0,0,.4);
              padding: 20Px 20Px 20PX 10Px;
              border-radius: 0 20Px 20Px 0;
          }
      }
  }
@media screen and (max-width:1900px) {
    .pho {
        width:100%;
    }
}
</style>
